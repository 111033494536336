import BasicInput from "components/controls/inputs/basicInput";
import { Partner } from "dataTypes/partners";
import { useState } from "react";
import { Link } from "react-router-dom";
import { secureAxios } from "utilities/apiService";
import AuthNavbar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { getAuthPath } from "../types/authPath";
import AuthFormButton from "./button";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";

interface MigrationFormProps {
  partner?: Partner;
}

export const MigrationForm: React.FC<MigrationFormProps> = (props) => {
  const userProfile = useSelector((state: ApplicationState) => state.account.signedInUser);

  console.log("userProfile", userProfile);
  const [password, setPassword] = useState("");

  const handleInput = (value: string) => {
    setPassword(value);
    console.log("password", password);
  };
  const handleClear = (name: string) => setPassword("");
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const res = await secureAxios.post("/api/Account/MigratePassword", {
      password,
      username: userProfile?.email,
    });
    console.log(res);
    // once we've migrated the password, we can redirect them to the new site
    window.location.href = "https://barr-dev.pro.flueid.com/auth/sign-in?email=" + userProfile?.email;
  };

  return (
    <div className="fade-in">
      <AuthNavbar>
        <Link to={getAuthPath("/auth/sign-in", props.partner)}>
          <div className="back-btn"></div>
        </Link>
        <AuthNavHeader>Update Password</AuthNavHeader>
        <AuthNavCloseBtn websiteUrl={props.partner?.websiteUrl} />
      </AuthNavbar>
      <div className="form-container auth-form-container">
        <div className="content">
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <BasicInput
                label="Password"
                type="password"
                name="new password"
                initialValue={password}
                placeHolder="Enter your new password"
                onChange={(_: string, value: string) => handleInput(value)}
                onClearClick={(name: string) => handleClear(name)}
                inputVersion="v2"
                focusOnMount
                valid={true}
                errorMessages={[]}
                autoComplete
              />
            </div>
            <AuthFormButton
              type="submit"
              className=""
              // className={
              //   !inputFields[currentFieldIndex].value || fieldInvalid || submitButtonDisabled ? "btn-disabled " : ""
              // }
              // disabled={fieldInvalid || submitButtonDisabled ? true : false}
              disabled={false}>
              Update Password
            </AuthFormButton>
          </form>
        </div>
      </div>
      {/* <Modal open={modalOpen} onClose={() => this.setState({ modalOpen: false })} size="small">
      <Modal.Header>
        <div className="terms-content_padding">
          <div className="flex justify-end">
            <div className="icon-close-black" onClick={() => this.setState({ modalOpen: false })} />
          </div>
        </div>
      </Modal.Header>
      <Modal.Content className="modal-content_overflowY">
        <div className="terms-content_padding">
          <Terms />
        </div>
      </Modal.Content>
      <Modal.Actions className="modal-actions_padding">
        <div className="flex justify-center terms-content_padding">
          <AuthFormButton onClick={this.handleAcceptClick}>Accept</AuthFormButton>
        </div>
      </Modal.Actions>
    </Modal> */}
    </div>
  );
};
