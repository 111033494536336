import * as React from "react";
import AuthService from "../../../utilities/authService";
import { Link, RouteComponentProps } from "react-router-dom";
import Validate from "../../../utilities/formFieldValidator";
import AuthNavBar, { AuthNavCloseBtn, AuthNavHeader } from "../authNavbar";
import { connect } from "react-redux";
import { CognitoUser } from "@aws-amplify/auth";
import { accountActionCreators } from "../../../store/account/actionCreators";
import { Partner } from "../../../dataTypes/partners";
import { hasMultiPartners, User } from "../../../dataTypes/user/user";
import { ApplicationState } from "../../../store";
import { Location } from "history";
import BasicInput from "../../controls/inputs/basicInput";
import { loadingBarActionCreators } from "../../../store/controls/loadingBar/loadingBarActionCreators";
import { LoadingBar } from "../../controls/loadingBar";
import { getAuthPath } from "../types/authPath";
import { AuthFormLogo, AuthNavbarLogo } from "./logos";
import { InputField } from "../types/inputField";
import AuthFormButton from "./button";
import { ApiEndpoints, stringifyParams } from "../../../utilities/apiService";
import Axios, { AxiosError, AxiosResponse } from "axios";
import ErrorHandler from "../../controls/errorHandler";
import AppConfig from "../../../utilities/appConfig";
import { fromSignInParam } from "components/controls/SwitchPartnersModal";
import { setCurrentPartner } from "components/controls/SwitchPartnersModal/queries";

const MAX_ATTEMPTS = 3;

interface State {
  currentFieldIndex: number;
  fieldInvalid: boolean;
  fieldInvalidMessage: string;
  inputFields: InputField[];
  signInAttempts: number;
  showForgotPasswordSuggestion: boolean;
  submitButtonDisabled: boolean;
}

interface ReduxStateProps {
  signedInUser: User | null;
}

interface ReduxDispatchProps {
  getSignedInUser: () => void;
  clearAccountStore: () => void;
  showLoadingBar: (scope?: string) => void;
  hideLoadingBar: (scope?: string) => void;
  setSignedInUser: (user: User) => void;
}

interface OwnProps {
  partner?: Partner;
  setSignInEmail: (email: string) => void;
  setAwsCognitoUser: (user?: CognitoUser) => void;
}

type Props = ReduxStateProps & RouteComponentProps & ReduxDispatchProps & OwnProps;

const initialState: State = {
  currentFieldIndex: 0,
  fieldInvalid: false,
  fieldInvalidMessage: "",
  inputFields: [
    { name: "email", label: "Email", value: "" },
    { name: "password", label: "Password", value: "" },
  ],
  showForgotPasswordSuggestion: false,
  signInAttempts: 0,
  submitButtonDisabled: false,
};

class SignIn extends React.Component<Props, State> {
  state: State = initialState;

  // create a ref to be assigned to the form inputs for auto focusing fields
  private inputRef = React.createRef<HTMLInputElement>();

  //-------------------
  // LIFECYCLE METHODS
  componentDidMount() {
    const { clearAccountStore, signedInUser } = this.props;

    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }

    if (signedInUser) {
      clearAccountStore();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { signedInUser, history, partner, setSignedInUser } = this.props;

    // look for a better more specific condition to focus the input
    const input = this.inputRef.current;

    if (input) {
      input.focus();
    }

    // when signedInUser received, check termsAccepted
    if (!prevProps.signedInUser && signedInUser) {
      const hasMultiplePartners = hasMultiPartners(signedInUser);
      // check if this is a branded sign in (partner prop exists) and if so, call SetPartner API automatically
      if (partner && hasMultiplePartners) {
        // ensure user has partner association. Ex. I could not be a Truly user but use the public truly sign in page and wouldn't want Truly set as my currently selected partner association
        const hasPartnerAssociation = signedInUser.partnerAssociations.find((p) => p.partnerId === partner.partnerId);
        if (hasPartnerAssociation) {
          setCurrentPartner(partner.partnerId)
            .then((resp) => setSignedInUser(resp.data))
            .catch((err) => console.error(err));
        }
      }

      const { migrationStatus } = signedInUser;
      const needsPasswordReset = migrationStatus === "needs-password-reset";
      const isRedirected = migrationStatus?.startsWith("redirect");

      const state = this.props.location.state as Location;
      if (needsPasswordReset) {
        history.push("/auth/update-password", state);
      } else if (isRedirected) {
        // the string will look like redirected=https://barr-dev.pro...
        // grab the url from the string and redirect the user there
        const url = migrationStatus!.split("=")[1];
        window.location.href = url;
      } else if (signedInUser.termsAccepted) {
        // grab any location state data that may have been passed along during redirection
        let redirectPath: string = "";
        let searchParams: string = "";

        if (state) {
          redirectPath = state.pathname || "";
          searchParams = state.search || "";
        }

        // user has multi partners & not using a branded sign in, show them the partner picker
        const usePartnerPicker = hasMultiplePartners && !partner ? fromSignInParam : "";

        // send them to their redirect path or default to properties page
        redirectPath ? history.push(redirectPath + searchParams) : history.push(`/app/properties${usePartnerPicker}`);
      } else {
        // send them to terms/conditions, pass along state as well in case of redirection
        history.push(getAuthPath("/auth/terms-conditions", partner), state);
      }
    }
  }
  //---------------------

  //-----------------
  // FORM HANDLING METHODS
  handleInput = (value: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    //make a copy of the fields
    const updatedFields = [...inputFields];
    //update the current fields value
    let v = value.trim();
    if (updatedFields[currentFieldIndex].name === "email") v = v.toLowerCase();
    updatedFields[currentFieldIndex] = { ...updatedFields[currentFieldIndex], value: v };

    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessage: "",
      submitButtonDisabled: false,
    });
  };

  handleClear = (name: string) => {
    const { inputFields, currentFieldIndex } = this.state;
    const updatedFields = [...inputFields];
    updatedFields[currentFieldIndex] = { ...updatedFields[currentFieldIndex], value: "" };

    this.setState({
      inputFields: updatedFields,
      fieldInvalid: false,
      fieldInvalidMessage: "",
      submitButtonDisabled: false,
    });
  };

  progressNextFormField = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { inputFields, currentFieldIndex } = this.state;

    // validate email
    if (inputFields[currentFieldIndex].name === "email") {
      if (!Validate.Email(inputFields[currentFieldIndex].value)) {
        this.setState({
          fieldInvalid: true,
          fieldInvalidMessage: "Please enter a correct email address",
        });
        return;
      }
    }

    if (inputFields[currentFieldIndex].value) {
      this.setState({ currentFieldIndex: currentFieldIndex + 1 });
    }
  };

  previousFormField = () => {
    this.setState({
      currentFieldIndex: this.state.currentFieldIndex - 1,
      fieldInvalid: false,
      fieldInvalidMessage: "",
      submitButtonDisabled: false,
    });
  };

  signIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { history, getSignedInUser, showLoadingBar, hideLoadingBar, setAwsCognitoUser } = this.props;
    const { inputFields, currentFieldIndex } = this.state;
    const field: InputField = inputFields[currentFieldIndex];

    if (field.value) {
      // disable submit button to prevent extra requests
      this.setState({ submitButtonDisabled: true });

      showLoadingBar();

      Axios.get<boolean>(
        AppConfig.ApiURL() + ApiEndpoints.ValidateUser + stringifyParams({ username: inputFields[0].value }),
      )
        .then((resp: AxiosResponse<boolean>) => {
          const validUser = resp.data;
          if (validUser) {
            AuthService.SignIn(inputFields[0].value, inputFields[1].value)
              .then((user) => {
                hideLoadingBar();

                // first time user, need to reset their password
                if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                  // NewUserResetPassword needs this user because it has necessary Session data that doesn't come from GetCurrentAuthenticatedUser
                  setAwsCognitoUser(user);
                  history.push(getAuthPath("/auth/new-user-reset-password", this.props.partner));
                } else {
                  // request signedInUser from our API to check their termsAccepted
                  getSignedInUser();
                }
              })
              .catch((error) => {
                const { signInAttempts } = this.state;

                hideLoadingBar();

                if (signInAttempts >= MAX_ATTEMPTS) {
                  this.setState({
                    showForgotPasswordSuggestion: true,
                    signInAttempts: signInAttempts + 1,
                    fieldInvalid: true,
                    fieldInvalidMessage: "",
                    inputFields: [
                      { name: "email", label: "Email", value: inputFields[0].value },
                      { name: "password", label: "Password", value: "" },
                    ],
                  });
                } else {
                  this.setState({
                    signInAttempts: signInAttempts + 1,
                    fieldInvalid: true,
                    fieldInvalidMessage: "Incorrect email or password",
                    submitButtonDisabled: false,
                    inputFields: [
                      { name: "email", label: "Email", value: inputFields[0].value },
                      { name: "password", label: "Password", value: "" },
                    ],
                  });
                }
              });
          } else {
            hideLoadingBar();
            this.setState({
              fieldInvalid: true,
              fieldInvalidMessage: "Incorrect email or password",
            });
          }
        })
        .catch((error: AxiosError) => {
          ErrorHandler.HandleAxiosError(error);
          hideLoadingBar();
        });
    } else {
      this.setState({
        fieldInvalid: true,
        fieldInvalidMessage: "Incorrect email or password",
      });
    }
  };
  //-----------------

  //---------------------------
  // RENDER UI ELEMENTS METHODS
  renderFormField = (currentFieldIndex: number) => {
    const { partner, setSignInEmail } = this.props;
    const { inputFields, fieldInvalid, fieldInvalidMessage, submitButtonDisabled } = this.state;
    const field: InputField = inputFields[currentFieldIndex];

    return (
      <div className="content">
        {partner?.logoMainUrl ? (
          <AuthFormLogo partner={partner} />
        ) : (
          <h3 className="header-3 boldest header">Designed for today's real estate pro.</h3>
        )}
        <form
          className="auth-form"
          onSubmit={currentFieldIndex === inputFields.length - 1 ? this.signIn : this.progressNextFormField}>
          <div key={`input-${field.name}`} className="form-group">
            <BasicInput
              label={field.label}
              type={field.name === "password" ? "password" : "text"}
              name={field.name}
              initialValue={field.value}
              placeHolder={field.label}
              onChange={(name: string, value: string) => this.handleInput(value)}
              onClearClick={(name: string) => this.handleClear(name)}
              inputVersion="v2"
              focusOnMount
              valid={!fieldInvalid}
              errorMessages={[fieldInvalidMessage]}
              autoComplete
            />
          </div>
          <AuthFormButton
            type="submit"
            className={!inputFields[currentFieldIndex].value || fieldInvalid ? "btn-disabled " : ""}
            disabled={fieldInvalid || submitButtonDisabled ? true : false}>
            {currentFieldIndex === inputFields.length - 1 ? "Sign In" : "Next"}
          </AuthFormButton>
        </form>
        <Link
          to={getAuthPath("/auth/reset-password", partner)}
          className={`partner-main-color  m-t_md cursor-pointer`}
          onClick={() => setSignInEmail(inputFields[0].value)}>
          Forgot Password?
        </Link>
      </div>
    );
  };

  renderForgotPasswordSuggestion = () => {
    const { partner, setSignInEmail } = this.props;
    const { inputFields } = this.state;
    return (
      <div className="content shorter-content">
        <h3 className="header">Did you forget your password?</h3>
        <Link to={getAuthPath("/auth/reset-password", partner)} onClick={() => setSignInEmail(inputFields[0].value)}>
          <AuthFormButton className="m-t_none">Yes, Reset My Password</AuthFormButton>
        </Link>
        <div onClick={() => this.setState({ ...initialState })} className="cursor-pointer m-t_md ">
          <u>No, Go Back To Sign In</u>
        </div>
      </div>
    );
  };

  render() {
    const { partner } = this.props;
    const { currentFieldIndex, showForgotPasswordSuggestion } = this.state;

    return (
      <div className="fade-in">
        <AuthNavBar>
          <div className="absolute">
            {currentFieldIndex === 0 && <AuthNavbarLogo partner={partner} />}
            {currentFieldIndex > 0 && (
              <div
                className="back-btn"
                onClick={
                  showForgotPasswordSuggestion ? () => this.setState({ ...initialState }) : this.previousFormField
                }></div>
            )}
          </div>
          <AuthNavHeader>Sign In</AuthNavHeader>
          <AuthNavCloseBtn websiteUrl={partner?.websiteUrl} />
        </AuthNavBar>
        <LoadingBar />
        <div className="auth-form-container">
          {showForgotPasswordSuggestion
            ? this.renderForgotPasswordSuggestion()
            : this.renderFormField(currentFieldIndex)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState): ReduxStateProps => ({
  signedInUser: state.account.signedInUser,
});

const mapDispatchToProps: ReduxDispatchProps = {
  getSignedInUser: accountActionCreators.getSignedInUser,
  clearAccountStore: accountActionCreators.clearAccountStore,
  showLoadingBar: loadingBarActionCreators.showLoadingBar,
  hideLoadingBar: loadingBarActionCreators.hideLoadingBar,
  setSignedInUser: accountActionCreators.setSignedInUser,
};

export default connect<ReduxStateProps, ReduxDispatchProps, OwnProps, ApplicationState>(
  mapStateToProps,
  mapDispatchToProps,
)(SignIn);
